<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

import BaseDialog from '@/components/dialogs/BaseDialog.vue'
const { t } = useI18n()

interface Props {
  loading: boolean
  email: string
  isLastSigner: boolean
}

withDefaults(defineProps<Props>(), {
  loading: false,
})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DocumentDialogRemoveSigner']['contextData']): void
}>()
</script>

<template>
  <base-dialog
    :title="isLastSigner ? t('remove_signer.dialog.title_last') : t('remove_signer.dialog.title')"
    :disabled="loading"
    :loading="loading"
    :action-text="t('remove_signer.remove_signer')"
    action-color="error"
    data-cy="removeSignerDialog"
    @action="emit('action')"
    @close="emit('close')"
  >
    <i18n path="remove_signer.dialog.text" tag="div" class="mb-4 text-subtitle-1">
      <template #email>
        <strong>{{ email }}</strong>
      </template>
    </i18n>
    <div v-if="isLastSigner" class="d-flex align-center text-subtitle-1 document-dialog-remove-signer__warn px-5 py-4">
      <span class="mr-4">
        <v-icon class="danger--text">$vuetify.icons.warning</v-icon>
      </span>
      {{ t('remove_signer.dialog.warn') }}
    </div>
  </base-dialog>
</template>

<style lang="sass">
$ns: document-dialog-remove-signer

.#{$ns}
  &__warn
    background: #FFDCE7
    color: #293D66
    border-radius: 4px
</style>
